import React from 'react';
import { FaTwitter, FaInstagram, FaTelegram } from 'react-icons/fa';

const Header = () => {
  return (
    <header className="bg-transparent text-white fixed top-0 w-full z-50 p-2 flex justify-between items-center backdrop-filter backdrop-blur-md bg-opacity-20">
      {/* Left Section: Logo */}
      <div className="logo flex gap-4">
        <img src="favicon.png" alt="Lucky Mantis" className="h-12 object-contain" />
        <p className="text-xl font-bold self-center">Lucky Mantis</p>
      </div>

      {/* Right Section: Social Icons */}
      <div className="social-icons flex space-x-4">
        <a href="https://twitter.com/luckymantisgame" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-400 transition-colors">
          <FaTwitter size="24" />
        </a>
        <a href="https://instagram.com/luckymantisgame" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-400 transition-colors">
          <FaInstagram size="24" />
        </a>
        <a href="https://t.me/luckymantis" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-400 transition-colors">
          <FaTelegram size="24" />
        </a>
      </div>
    </header>
  );
};

export default Header;
