import React, { useState } from 'react';
import './MainContent.css';

const generateRandomCoins = (count) => {
  return Array.from({ length: count }, () => ({
    id: Math.random(),
    type: Math.random() > 0.5 ? 'bitcoin' : 'ethereum', // Randomly choose between Bitcoin or Ethereum
    left: Math.random() * 100, // Random left position
    duration: Math.random() * 3 + 4, // Random duration between 4 and 7 seconds
    delay: Math.random() * 3, // Random delay up to 3 seconds
  }));
};

const MainContent = () => {
    const [coins, setCoins] = useState(generateRandomCoins(20)); // Start with 20 coins
    const [clicks, setClicks] = useState([]); // Store clicked coin animations
  
    const handleCoinClick = (id, left, top) => {
      // Remove the clicked coin and show a +1 at the clicked location
      setCoins((prev) => prev.filter((coin) => coin.id !== id));
      setClicks((prev) => [...prev, { left, top }]);
  
      // Add new coin to keep at least 20 coins on screen
      setTimeout(() => {
        setCoins((prev) => [...prev, ...generateRandomCoins(1)]);
        setClicks((prev) => prev.filter((click) => click.left !== left));
      }, 1000); // Remove the +1 after 1 second
    };
  
    const handleMouseDown = (e, coin) => {
      e.preventDefault(); // Prevent default context menu for right-click
      handleCoinClick(coin.id, e.clientX, e.clientY);
    };

  return (
    <div className="flex justify-center items-center h-screen text-white bg-gray-900 relative overflow-hidden">
      {/* Background Coins Rain */}
      <div className="coin-rain">
      {coins.map((coin) => (
          <img
            key={coin.id}
            src={coin.type === 'bitcoin' ? 'btc-mantis.png' : 'eth-mantis.png'}
            alt={coin.type}
            className="coin fall"
            style={{
              left: `${coin.left}vw`,
              animationDuration: `${coin.duration}s`,
              animationDelay: `${coin.delay}s`,
            }}
            onMouseDown={(e) => handleMouseDown(e, coin)} // Handle left click or mouse hold
            onContextMenu={(e) => handleMouseDown(e, coin)} // Handle right-click
          />
        ))}
      </div>

      {/* +1 animation when a coin is clicked */}
      {clicks.map((click, index) => (
        <span
          key={index}
          className="plus-one"
          style={{
            left: click.left,
            top: click.top,
          }}
        >
          +1
        </span>
      ))}

      {/* Main Content */}
      <div className="text-center z-10 mt-[-24px]">
        <img src="main-image.png" alt="Mantis Mascot" className="w-full max-w-md mx-auto select-none" />
        <h1 className="text-4xl font-bold mt-4">Welcome to Lucky Mantis!</h1>
        <p className="text-lg mt-2 max-w-lg mx-auto mb-8">
          Play and win in this fun, Ethereum and Bitcoin based coin-flip game.
        </p>
        <a
            href="https://t.me/luckymantis_bot/game"
            target="_blank"
          className="bg-gradient-to-r from-blue-500 to-green-500 text-white text-xl font-bold py-3 px-8 rounded-full hover:scale-105 transition-transform duration-300 ease-in-out"
        >
          Play Game
        </a>
      </div>
    </div>
  );
};

export default MainContent;

